import React from 'react';

function Footer() {
  return (
    <footer className="bg-primary-dark text-center py-6 mt-12">
      <p className="text-primary-yellow">© 2024 Franquicias Master. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;
