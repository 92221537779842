import React from 'react';

function Contact() {
  return (
    <section style={{ textAlign: 'center', padding: '50px', color: '#e0e0e0' }}>
      <h2>Contacto</h2>
      <p>Para consultas rápidas, contáctanos en WhatsApp:</p>
      <a href="https://wa.me/593999442670" target="_blank" rel="noopener noreferrer">
        <button>Enviar mensaje</button>
      </a>
    </section>
  );
}

export default Contact;
