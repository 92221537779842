import React from 'react';
import { Link } from 'react-router-dom';

function Blog() {
  return (
    <section className="bg-gray-800 py-12 text-white">
      <div className="container mx-auto text-center mb-8">
        <h3 className="text-3xl font-semibold mb-6">Blog de Franquicias</h3>
        <p className="text-gray-400 mb-4">Artículos sobre cómo expandir y optimizar franquicias en el mercado actual.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-6">
        <Link to="/blog/article1" className="bg-gray-700 p-6 rounded-lg shadow-lg hover:bg-teal-500 hover:text-white transition duration-300 ease-in-out">Cómo Expandir tu Restaurante con una Franquicia en América Latina</Link>
        <Link to="/blog/article2" className="bg-gray-700 p-6 rounded-lg shadow-lg hover:bg-teal-500 hover:text-white transition duration-300 ease-in-out">Impacto de la Cultura Culinaria Latinoamericana en el Mercado Global</Link>
        <Link to="/blog/article3" className="bg-gray-700 p-6 rounded-lg shadow-lg hover:bg-teal-500 hover:text-white transition duration-300 ease-in-out">Estrategias de Marketing Digital para Franquicias</Link>
      </div>
    </section>
  );
}

export default Blog;
