import React from 'react';
import ServiceCard from '../../components/ServiceCard';

function Services() {
  const services = [
    { title: 'Evaluación de Franquicias', description: 'Analizamos opciones de franquicias teniendo en cuenta factores como rentabilidad, sector y reputación.' },
    { title: 'Asesoría Legal y Financiera', description: 'Acompañamos en todos los aspectos legales y financieros para establecer una franquicia exitosa.' },
    { title: 'Automatización de Operaciones', description: 'Transformamos tu negocio en un sistema altamente eficiente y rentable.' },
    { title: 'Formación de Equipos', description: 'Capacitamos al equipo en atención al cliente, manejo de menú y eficiencia operativa.' },
    { title: 'Optimización de Menú y Costos', description: 'Re-ingeniería de menú con costos exactos y optimización de precios para mejorar la rentabilidad.' },
    { title: 'Creación y Remodelación de Conceptos', description: 'Diseño y construcción de espacios que maximizan eficiencia y atractivo visual.' }
  ];

  return (
    <section className="bg-gray-900 py-12">
      <div className="container mx-auto text-center mb-8">
        <h3 className="text-3xl text-white font-semibold mb-6">Nuestros Servicios</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-6">
        {services.map((service, index) => (
          <ServiceCard key={index} title={service.title} description={service.description} />
        ))}
      </div>
    </section>
  );
}

export default Services;
