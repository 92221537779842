import React from 'react';

function LandingPage() {
  return (
    <section className="bg-gray-100 text-gray-800 py-16 px-6">
      <div className="container mx-auto text-center space-y-12">
        
        {/* Sección de Introducción */}
        <div className="space-y-4">
          <h2 className="text-4xl font-bold text-yellow-500">
            Consultoría Especializada en Franquicias
          </h2>
          <p className="text-lg text-gray-700 max-w-xl mx-auto">
            Llevamos más de 20 años brindando consultoría especializada a franquicias con un servicio único de acompañamiento. 
            Asesoramos y orientamos a emprendedores que desean invertir en una franquicia.
          </p>
        </div>

        {/* Sección de Servicios Destacados */}
        <div className="space-y-8">
          <h3 className="text-3xl font-semibold text-yellow-500">
            Nuestros Servicios
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-red-500 hover:text-white transition duration-300">
              <h4 className="text-xl font-bold text-yellow-500">Evaluación de Franquicias</h4>
              <p className="text-gray-700">
                Analizamos opciones de franquicias considerando factores como rentabilidad, sector y reputación.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-red-500 hover:text-white transition duration-300">
              <h4 className="text-xl font-bold text-yellow-500">Asesoría Legal y Financiera</h4>
              <p className="text-gray-700">
                Acompañamiento en todos los aspectos legales y financieros necesarios para establecer una franquicia exitosa.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-red-500 hover:text-white transition duration-300">
              <h4 className="text-xl font-bold text-yellow-500">Automatización de Operaciones</h4>
              <p className="text-gray-700">
                Transformamos tu negocio en un sistema altamente eficiente y rentable, optimizando cada proceso.
              </p>
            </div>
          </div>
        </div>

        {/* Sección de Llamada a la Acción */}
        <div className="bg-red-500 p-10 rounded-lg text-center text-white space-y-8 shadow-lg">
          <h3 className="text-2xl font-bold">¿Listo para transformar tu negocio?</h3>
          <p className="text-lg text-white">
            Nuestro equipo de consultores está listo para ayudarte a lograr el éxito en el mundo de las franquicias. 
            Desde la evaluación de opciones hasta la gestión operativa y financiera, estamos aquí para acompañarte en cada paso.
          </p>
          <a
            href="https://wa.me/593999442670"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-yellow-500 text-red-500 font-bold py-4 px-10 rounded-lg border border-yellow-700 shadow-lg hover:bg-yellow-600 hover:text-white transition duration-300 no-underline"
            style={{ textDecoration: 'none' }}
          >
            Solicitar Consultoría
          </a>
        </div>
      </div>
      <div className="mt-12"></div> {/* Espacio adicional entre la sección y el footer */}
    </section>
  );
}

export default LandingPage;
