import React from 'react';

function ServiceCard({ title, description }) {
  return (
    <div className="bg-primary-dark p-6 rounded-lg shadow-lg hover:bg-primary-red hover:text-white transition duration-300 ease-in-out">
      <h4 className="text-xl font-bold mb-2" style={{ color: '#ffc72c' }}>{title}</h4>
      <p>{description}</p>
    </div>
  );
}

export default ServiceCard;
