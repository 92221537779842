import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav style={{ backgroundColor: '#ffffff', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '16px 0' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '1200px', margin: '0 auto' }}>
        
        {/* Logo */}
        <h1 style={{ color: '#ffc72c', fontSize: '24px', fontWeight: 'bold' }}>Franquicias Master</h1>
        
        {/* Menú de Navegación (Forzado en Línea) */}
        <ul style={{ display: 'flex', gap: '24px', listStyle: 'none', margin: '0', padding: '0' }}>
          <li><Link to="/" style={{ color: '#333333', fontWeight: '600', textDecoration: 'none' }}>Inicio</Link></li>
          <li><Link to="/services" style={{ color: '#333333', fontWeight: '600', textDecoration: 'none' }}>Servicios</Link></li>
          <li><Link to="/blog" style={{ color: '#333333', fontWeight: '600', textDecoration: 'none' }}>Blog</Link></li>
          <li><Link to="/contact" style={{ color: '#333333', fontWeight: '600', textDecoration: 'none' }}>Contacto</Link></li>
        </ul>

        {/* Botón de Ingreso */}
        <button style={{ backgroundColor: '#ffc72c', color: '#ffffff', fontWeight: 'bold', padding: '8px 16px', borderRadius: '8px', border: 'none', cursor: 'pointer' }}>
          Contáctanos
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
